import { useTranslate } from "react-admin";
import { Typography } from "@mui/material";
import PageHeader from "../Header/PageHeader";
import EditFAQ from "./EditFAQ";
import { MenuItem, Select } from "@mui/material";
import { useState } from "react";
import {
  COD_DASHBOARD,
  ROD_DASHBOARD,
  SPD_DASHBOARD,
  CTD_DASHBOARD,
} from "../../definition/definition";

const AdminFAQ = () => {
  const translate = useTranslate();
  const DASHBOARDS = [
    {
      id: ROD_DASHBOARD,
      title: translate("nav.rodDashboard.appTitle"),
    },
    {
      id: COD_DASHBOARD,
      title: translate("nav.codDashboard.appTitle"),
    },
    {
      id: SPD_DASHBOARD,
      title: translate("nav.spdDashboard.appTitle"),
    },
    {
      id: CTD_DASHBOARD,
      title: translate("nav.ctdDashboard.appTitle"),
    },
  ];
  const [selected, setSelected] = useState(DASHBOARDS[0].id);

  return (
    <>
      <PageHeader title="FAQ Page Editor" descriptionKey="admin.faq.header" />
      <Typography
        key={"dashboard-title"}
        variant="body2"
        fontWeight={600}
        marginBottom={"0.5em"}
      >
        Dashboard
      </Typography>
      <Select
        labelId="dashboards"
        id="dashboard-field"
        value={selected}
        onChange={(e) => {
          if (e.target.value) {
            setSelected(e.target.value);
          }
        }}
      >
        {DASHBOARDS.map((dashboard) => (
          <MenuItem key={dashboard.id} value={dashboard.id}>
            {dashboard.title}
          </MenuItem>
        ))}
      </Select>
      <EditFAQ dashboard={selected} />
    </>
  );
};

export default AdminFAQ;
