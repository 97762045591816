import { Divider, Link, Stack, Typography, Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import { API } from "aws-amplify";
import { useContext, useEffect, useState } from "react";
import {
  Menu,
  Sidebar,
  defaultTheme,
  useBasename,
  useCreatePath,
  useGetResourceLabel,
  useResourceDefinitions,
  useSidebarState,
  useTranslate,
} from "react-admin";
import { Link as RouterLink } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import { handleFetchError } from "../../errors/ErrorHandling";
import { CustomMenuItemLink } from "./CustomMenuItem";
import SideBarHeader from "./SideBarHeader";
import { ResmedSidebarToggleButton } from "./SidebarToggleMenu";
import { OpenInNew, InfoOutlined } from "@mui/icons-material";
import { DATADOG_LINK_MONITORING } from "../../definition/definition";

interface StyledMenuLinkProps {
  to: string;
  label: string;
  external?: boolean;
}

interface LinkMonitoring {
  datadogActionName?: string;
}

const linkStyles = {
  textDecoration: "none",
  fontSize: "12px",
  fontWeight: 600,
  display: "inline-flex",
  flexDirection: "row",
  justifyContent: "space-between",
  flex: 1,
};

const fontStyles = {
  fontSize: "12px",
  fontWeight: 600,
};

const textStyles = {
  textDecoration: "none",
  ...fontStyles,
};

const StyledMenuLink = ({
  to,
  label,
  external = false,
  datadogActionName,
}: StyledMenuLinkProps & LinkMonitoring) => {
  const linkProps = external
    ? { href: to, target: "_blank", rel: "noopener noreferrer" }
    : { component: RouterLink, to };

  return (
    <Link
      sx={linkStyles}
      {...linkProps}
      data-dd-action-name={datadogActionName}
    >
      <Typography sx={textStyles}>{label}</Typography>
      {external && <OpenInNew sx={{ fontSize: "17px" }} />}
    </Link>
  );
};

const containerStyles = {
  width: "100%",
  display: "flex",
  padding: "0 1.5em",
  marginTop: 0,
  marginBottom: "1em",
  alignSelf: "flex-start",
  flexDirection: "column",
  flex: 1,
  gap: "0.7em",
};

const ResmedSidebarFooter = () => {
  const appContext = useContext(AppContext);
  const basename = useBasename();
  return (
    <Stack sx={{ paddingBottom: "1em", marginTop: "auto" }} alignItems="center">
      <Box sx={containerStyles}>
        <Box
          sx={{
            display: "inline-flex",
            justifyContent: "space-between",
            flexDirection: "row",
            flex: 1,
          }}
        >
          <Typography variant="subtitle2" sx={fontStyles}>
            Other resources
          </Typography>
          <Link href={`${basename}/faq#other`} target="_blank">
            <Tooltip
              title={<Typography sx={fontStyles}>FAQ page</Typography>}
              placement="bottom-start"
              arrow
            >
              <InfoOutlined
                sx={{
                  alignSelf: "baseline",
                  color: "#424243",
                  fontSize: 16,
                  fontWeight: 600,
                }}
              />
            </Tooltip>
          </Link>
        </Box>
        <StyledMenuLink
          to="https://developer.devx.resmed.com/"
          label="Developer Portal"
          external
          datadogActionName={DATADOG_LINK_MONITORING.DEVELOPER_PORTAL}
        />
        <StyledMenuLink
          to="https://docs.devx.resmed.com/learn/ccoe/"
          label="DevX Doc. Portal"
          external
          datadogActionName={DATADOG_LINK_MONITORING.DEVX_DOCS_PORTAL}
        />
        <StyledMenuLink
          to="https://developer.devx.resmed.com/Kaiju"
          label="Manage AWS Accounts"
          external
          datadogActionName={DATADOG_LINK_MONITORING.KAIJU_PORTAL}
        />
        <StyledMenuLink
          to="https://xms.resmed.com/"
          label="RMD MS Portal"
          external
          datadogActionName={DATADOG_LINK_MONITORING.RMD_MS_PORTAL}
        />
        <StyledMenuLink
          to="https://console.vantage.sh/"
          label="Vantage"
          external
          datadogActionName={DATADOG_LINK_MONITORING.VANTAGE}
        />
      </Box>
      <Typography variant="subtitle2">v{appContext.version}</Typography>
      <Typography variant="subtitle2" sx={{ color: "darkGrey" }}>
        Last updated: {appContext.lastIngestionTimestamp}
      </Typography>
    </Stack>
  );
};

export const ResourceMenuItem = ({
  name,
  options,
}: {
  name: string;
  options: { label: string; fetchTotal: boolean };
}) => {
  const resources = useResourceDefinitions();
  const { fetchTotal } = options;
  const getResourceLabel = useGetResourceLabel();
  const createPath = useCreatePath();
  const [resourceTotal, setResourceTotal] = useState("");
  const translate = useTranslate();
  const basename = useBasename();

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await API.get("TrustAPI", `assets/${name}/total`, {});
        setResourceTotal(response.total);
      } catch (error) {
        const errorMessage = handleFetchError(error, translate);
        console.error(errorMessage);
      }
    }
    if (fetchTotal !== false) {
      fetchData();
    }
  }, [name, fetchTotal, translate, basename]);

  if (!resources || !resources[name]) return null;
  return (
    <CustomMenuItemLink
      to={createPath({
        resource: name,
        type: "list",
      })}
      resourceTotal={resourceTotal}
      state={{ _scrollToTop: true }}
      primaryText={<>{getResourceLabel(name, 2)}</>}
    />
  );
};

const MenuContent = ({ dashboard }: { dashboard: string | undefined }) => {
  const resources = useResourceDefinitions();
  if (!resources) return null;

  return (
    <Box
      sx={{
        display: "flex",
        height: 1,
        flexDirection: "column",
        marginTop: 0,
      }}
    >
      <Menu
        sx={{
          marginTop: 0,
        }}
      >
        {(dashboard === "rodDashboard" || dashboard === "spdDashboard") && (
          <Menu.DashboardItem primaryText="Dashboard Home" />
        )}
        {Object.keys(resources)
          .filter((name) => resources[name].hasList)
          .map((name) => (
            <ResourceMenuItem
              key={name}
              name={name}
              options={resources[name]?.options || {}}
            />
          ))}
        {dashboard === "ctdDashboard" && [
          <Menu.Item to="/ctd/awsorg" primaryText="AWS orgs usage" />,
          <Menu.Item to="/ctd/storage" primaryText="AWS Storage/DB usage" />,
          <Menu.Item to="/ctd/container" primaryText="Containers usage" />,
          <Divider />,
          <Menu.Item
            to="/ctd/focus"
            primaryText="Time-sensitive initiatives"
          />,
        ]}
      </Menu>
      <ResmedSidebarFooter />
    </Box>
  );
};

export const ResmedSidebar = (props: any) => {
  const appContext = useContext(AppContext);
  const [open] = useSidebarState();
  return (
    <Sidebar
      sx={{
        "& .RaSidebar-fixed": {
          width: open ? defaultTheme?.sidebar?.width : 68,
        },
      }}
      {...props}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: 1,
        }}
      >
        {open && (
          <SideBarHeader
            title={`nav.${appContext.app}.appTitle`}
            description={`nav.${appContext.app}.description`}
          />
        )}
        <ResmedSidebarToggleButton />
      </Box>
      {open && <MenuContent dashboard={appContext.app} />}
    </Sidebar>
  );
};
